export default {
  "abstract": require("css.gg/icons/svg/abstract.svg"),
  "add-r": require("css.gg/icons/svg/add-r.svg"),
  "add": require("css.gg/icons/svg/add.svg"),
  "adidas": require("css.gg/icons/svg/adidas.svg"),
  "airplane": require("css.gg/icons/svg/airplane.svg"),
  "alarm": require("css.gg/icons/svg/alarm.svg"),
  "album": require("css.gg/icons/svg/album.svg"),
  "align-bottom": require("css.gg/icons/svg/align-bottom.svg"),
  "align-center": require("css.gg/icons/svg/align-center.svg"),
  "align-left": require("css.gg/icons/svg/align-left.svg"),
  "align-middle": require("css.gg/icons/svg/align-middle.svg"),
  "align-right": require("css.gg/icons/svg/align-right.svg"),
  "align-top": require("css.gg/icons/svg/align-top.svg"),
  "anchor": require("css.gg/icons/svg/anchor.svg"),
  "apple-watch": require("css.gg/icons/svg/apple-watch.svg"),
  "arrange-back": require("css.gg/icons/svg/arrange-back.svg"),
  "arrange-front": require("css.gg/icons/svg/arrange-front.svg"),
  "arrow-align-h": require("css.gg/icons/svg/arrow-align-h.svg"),
  "arrow-align-v": require("css.gg/icons/svg/arrow-align-v.svg"),
  "arrow-bottom-left-o": require("css.gg/icons/svg/arrow-bottom-left-o.svg"),
  "arrow-bottom-left-r": require("css.gg/icons/svg/arrow-bottom-left-r.svg"),
  "arrow-bottom-left": require("css.gg/icons/svg/arrow-bottom-left.svg"),
  "arrow-bottom-right-o": require("css.gg/icons/svg/arrow-bottom-right-o.svg"),
  "arrow-bottom-right-r": require("css.gg/icons/svg/arrow-bottom-right-r.svg"),
  "arrow-bottom-right": require("css.gg/icons/svg/arrow-bottom-right.svg"),
  "arrow-down-o": require("css.gg/icons/svg/arrow-down-o.svg"),
  "arrow-down-r": require("css.gg/icons/svg/arrow-down-r.svg"),
  "arrow-down": require("css.gg/icons/svg/arrow-down.svg"),
  "arrow-left-o": require("css.gg/icons/svg/arrow-left-o.svg"),
  "arrow-left-r": require("css.gg/icons/svg/arrow-left-r.svg"),
  "arrow-left": require("css.gg/icons/svg/arrow-left.svg"),
  "arrow-long-down-c": require("css.gg/icons/svg/arrow-long-down-c.svg"),
  "arrow-long-down-e": require("css.gg/icons/svg/arrow-long-down-e.svg"),
  "arrow-long-down-l": require("css.gg/icons/svg/arrow-long-down-l.svg"),
  "arrow-long-down-r": require("css.gg/icons/svg/arrow-long-down-r.svg"),
  "arrow-long-down": require("css.gg/icons/svg/arrow-long-down.svg"),
  "arrow-long-left-c": require("css.gg/icons/svg/arrow-long-left-c.svg"),
  "arrow-long-left-e": require("css.gg/icons/svg/arrow-long-left-e.svg"),
  "arrow-long-left-l": require("css.gg/icons/svg/arrow-long-left-l.svg"),
  "arrow-long-left-r": require("css.gg/icons/svg/arrow-long-left-r.svg"),
  "arrow-long-left": require("css.gg/icons/svg/arrow-long-left.svg"),
  "arrow-long-right-c": require("css.gg/icons/svg/arrow-long-right-c.svg"),
  "arrow-long-right-e": require("css.gg/icons/svg/arrow-long-right-e.svg"),
  "arrow-long-right-l": require("css.gg/icons/svg/arrow-long-right-l.svg"),
  "arrow-long-right-r": require("css.gg/icons/svg/arrow-long-right-r.svg"),
  "arrow-long-right": require("css.gg/icons/svg/arrow-long-right.svg"),
  "arrow-long-up-c": require("css.gg/icons/svg/arrow-long-up-c.svg"),
  "arrow-long-up-e": require("css.gg/icons/svg/arrow-long-up-e.svg"),
  "arrow-long-up-l": require("css.gg/icons/svg/arrow-long-up-l.svg"),
  "arrow-long-up-r": require("css.gg/icons/svg/arrow-long-up-r.svg"),
  "arrow-long-up": require("css.gg/icons/svg/arrow-long-up.svg"),
  "arrow-right-o": require("css.gg/icons/svg/arrow-right-o.svg"),
  "arrow-right-r": require("css.gg/icons/svg/arrow-right-r.svg"),
  "arrow-right": require("css.gg/icons/svg/arrow-right.svg"),
  "arrow-top-left-o": require("css.gg/icons/svg/arrow-top-left-o.svg"),
  "arrow-top-left-r": require("css.gg/icons/svg/arrow-top-left-r.svg"),
  "arrow-top-left": require("css.gg/icons/svg/arrow-top-left.svg"),
  "arrow-top-right-o": require("css.gg/icons/svg/arrow-top-right-o.svg"),
  "arrow-top-right-r": require("css.gg/icons/svg/arrow-top-right-r.svg"),
  "arrow-top-right": require("css.gg/icons/svg/arrow-top-right.svg"),
  "arrow-up-o": require("css.gg/icons/svg/arrow-up-o.svg"),
  "arrow-up-r": require("css.gg/icons/svg/arrow-up-r.svg"),
  "arrow-up": require("css.gg/icons/svg/arrow-up.svg"),
  "arrows-breake-h": require("css.gg/icons/svg/arrows-breake-h.svg"),
  "arrows-breake-v": require("css.gg/icons/svg/arrows-breake-v.svg"),
  "arrows-exchange-alt-v": require("css.gg/icons/svg/arrows-exchange-alt-v.svg"),
  "arrows-exchange-alt": require("css.gg/icons/svg/arrows-exchange-alt.svg"),
  "arrows-exchange-v": require("css.gg/icons/svg/arrows-exchange-v.svg"),
  "arrows-exchange": require("css.gg/icons/svg/arrows-exchange.svg"),
  "arrows-expand-down-left": require("css.gg/icons/svg/arrows-expand-down-left.svg"),
  "arrows-expand-down-right": require("css.gg/icons/svg/arrows-expand-down-right.svg"),
  "arrows-expand-left-alt": require("css.gg/icons/svg/arrows-expand-left-alt.svg"),
  "arrows-expand-left": require("css.gg/icons/svg/arrows-expand-left.svg"),
  "arrows-expand-right-alt": require("css.gg/icons/svg/arrows-expand-right-alt.svg"),
  "arrows-expand-right": require("css.gg/icons/svg/arrows-expand-right.svg"),
  "arrows-expand-up-left": require("css.gg/icons/svg/arrows-expand-up-left.svg"),
  "arrows-expand-up-right": require("css.gg/icons/svg/arrows-expand-up-right.svg"),
  "arrows-h-alt": require("css.gg/icons/svg/arrows-h-alt.svg"),
  "arrows-h": require("css.gg/icons/svg/arrows-h.svg"),
  "arrows-merge-alt-h": require("css.gg/icons/svg/arrows-merge-alt-h.svg"),
  "arrows-merge-alt-v": require("css.gg/icons/svg/arrows-merge-alt-v.svg"),
  "arrows-scroll-h": require("css.gg/icons/svg/arrows-scroll-h.svg"),
  "arrows-scroll-v": require("css.gg/icons/svg/arrows-scroll-v.svg"),
  "arrows-shrink-h": require("css.gg/icons/svg/arrows-shrink-h.svg"),
  "arrows-shrink-v": require("css.gg/icons/svg/arrows-shrink-v.svg"),
  "arrows-v-alt": require("css.gg/icons/svg/arrows-v-alt.svg"),
  "arrows-v": require("css.gg/icons/svg/arrows-v.svg"),
  "assign": require("css.gg/icons/svg/assign.svg"),
  "asterisk": require("css.gg/icons/svg/asterisk.svg"),
  "atlasian": require("css.gg/icons/svg/atlasian.svg"),
  "attachment": require("css.gg/icons/svg/attachment.svg"),
  "attribution": require("css.gg/icons/svg/attribution.svg"),
  "awards": require("css.gg/icons/svg/awards.svg"),
  "backspace": require("css.gg/icons/svg/backspace.svg"),
  "band-aid": require("css.gg/icons/svg/band-aid.svg"),
  "battery-empty": require("css.gg/icons/svg/battery-empty.svg"),
  "battery-full": require("css.gg/icons/svg/battery-full.svg"),
  "battery": require("css.gg/icons/svg/battery.svg"),
  "bee": require("css.gg/icons/svg/bee.svg"),
  "bell": require("css.gg/icons/svg/bell.svg"),
  "bitbucket": require("css.gg/icons/svg/bitbucket.svg"),
  "block": require("css.gg/icons/svg/block.svg"),
  "bmw": require("css.gg/icons/svg/bmw.svg"),
  "board": require("css.gg/icons/svg/board.svg"),
  "bolt": require("css.gg/icons/svg/bolt.svg"),
  "bookmark": require("css.gg/icons/svg/bookmark.svg"),
  "border-all": require("css.gg/icons/svg/border-all.svg"),
  "border-bottom": require("css.gg/icons/svg/border-bottom.svg"),
  "border-left": require("css.gg/icons/svg/border-left.svg"),
  "border-right": require("css.gg/icons/svg/border-right.svg"),
  "border-style-dashed": require("css.gg/icons/svg/border-style-dashed.svg"),
  "border-style-dotted": require("css.gg/icons/svg/border-style-dotted.svg"),
  "border-style-solid": require("css.gg/icons/svg/border-style-solid.svg"),
  "border-top": require("css.gg/icons/svg/border-top.svg"),
  "bot": require("css.gg/icons/svg/bot.svg"),
  "bowl": require("css.gg/icons/svg/bowl.svg"),
  "box": require("css.gg/icons/svg/box.svg"),
  "boy": require("css.gg/icons/svg/boy.svg"),
  "brackets": require("css.gg/icons/svg/brackets.svg"),
  "briefcase": require("css.gg/icons/svg/briefcase.svg"),
  "browse": require("css.gg/icons/svg/browse.svg"),
  "browser": require("css.gg/icons/svg/browser.svg"),
  "brush": require("css.gg/icons/svg/brush.svg"),
  "bulb": require("css.gg/icons/svg/bulb.svg"),
  "c-plus-plus": require("css.gg/icons/svg/c-plus-plus.svg"),
  "calculator": require("css.gg/icons/svg/calculator.svg"),
  "calendar-dates": require("css.gg/icons/svg/calendar-dates.svg"),
  "calendar-due": require("css.gg/icons/svg/calendar-due.svg"),
  "calendar-next": require("css.gg/icons/svg/calendar-next.svg"),
  "calendar-today": require("css.gg/icons/svg/calendar-today.svg"),
  "calendar-two": require("css.gg/icons/svg/calendar-two.svg"),
  "calendar": require("css.gg/icons/svg/calendar.svg"),
  "calibrate": require("css.gg/icons/svg/calibrate.svg"),
  "camera": require("css.gg/icons/svg/camera.svg"),
  "cap": require("css.gg/icons/svg/cap.svg"),
  "captions": require("css.gg/icons/svg/captions.svg"),
  "card-clubs": require("css.gg/icons/svg/card-clubs.svg"),
  "card-diamonds": require("css.gg/icons/svg/card-diamonds.svg"),
  "card-hearts": require("css.gg/icons/svg/card-hearts.svg"),
  "card-spades": require("css.gg/icons/svg/card-spades.svg"),
  "carousel": require("css.gg/icons/svg/carousel.svg"),
  "cast": require("css.gg/icons/svg/cast.svg"),
  "chanel": require("css.gg/icons/svg/chanel.svg"),
  "chart": require("css.gg/icons/svg/chart.svg"),
  "check-o": require("css.gg/icons/svg/check-o.svg"),
  "check-r": require("css.gg/icons/svg/check-r.svg"),
  "check": require("css.gg/icons/svg/check.svg"),
  "chevron-double-down-o": require("css.gg/icons/svg/chevron-double-down-o.svg"),
  "chevron-double-down-r": require("css.gg/icons/svg/chevron-double-down-r.svg"),
  "chevron-double-down": require("css.gg/icons/svg/chevron-double-down.svg"),
  "chevron-double-left-o": require("css.gg/icons/svg/chevron-double-left-o.svg"),
  "chevron-double-left-r": require("css.gg/icons/svg/chevron-double-left-r.svg"),
  "chevron-double-left": require("css.gg/icons/svg/chevron-double-left.svg"),
  "chevron-double-right-o": require("css.gg/icons/svg/chevron-double-right-o.svg"),
  "chevron-double-right-r": require("css.gg/icons/svg/chevron-double-right-r.svg"),
  "chevron-double-right": require("css.gg/icons/svg/chevron-double-right.svg"),
  "chevron-double-up-o": require("css.gg/icons/svg/chevron-double-up-o.svg"),
  "chevron-double-up-r": require("css.gg/icons/svg/chevron-double-up-r.svg"),
  "chevron-double-up": require("css.gg/icons/svg/chevron-double-up.svg"),
  "chevron-down-o": require("css.gg/icons/svg/chevron-down-o.svg"),
  "chevron-down-r": require("css.gg/icons/svg/chevron-down-r.svg"),
  "chevron-down": require("css.gg/icons/svg/chevron-down.svg"),
  "chevron-left-o": require("css.gg/icons/svg/chevron-left-o.svg"),
  "chevron-left-r": require("css.gg/icons/svg/chevron-left-r.svg"),
  "chevron-left": require("css.gg/icons/svg/chevron-left.svg"),
  "chevron-right-o": require("css.gg/icons/svg/chevron-right-o.svg"),
  "chevron-right-r": require("css.gg/icons/svg/chevron-right-r.svg"),
  "chevron-right": require("css.gg/icons/svg/chevron-right.svg"),
  "chevron-up-o": require("css.gg/icons/svg/chevron-up-o.svg"),
  "chevron-up-r": require("css.gg/icons/svg/chevron-up-r.svg"),
  "chevron-up": require("css.gg/icons/svg/chevron-up.svg"),
  "circleci": require("css.gg/icons/svg/circleci.svg"),
  "clapper-board": require("css.gg/icons/svg/clapper-board.svg"),
  "clipboard": require("css.gg/icons/svg/clipboard.svg"),
  "close-o": require("css.gg/icons/svg/close-o.svg"),
  "close-r": require("css.gg/icons/svg/close-r.svg"),
  "close": require("css.gg/icons/svg/close.svg"),
  "cloud": require("css.gg/icons/svg/cloud.svg"),
  "code-climate": require("css.gg/icons/svg/code-climate.svg"),
  "code-slash": require("css.gg/icons/svg/code-slash.svg"),
  "code": require("css.gg/icons/svg/code.svg"),
  "coffee": require("css.gg/icons/svg/coffee.svg"),
  "collage": require("css.gg/icons/svg/collage.svg"),
  "color-bucket": require("css.gg/icons/svg/color-bucket.svg"),
  "color-picker": require("css.gg/icons/svg/color-picker.svg"),
  "comedy-central": require("css.gg/icons/svg/comedy-central.svg"),
  "comment": require("css.gg/icons/svg/comment.svg"),
  "community": require("css.gg/icons/svg/community.svg"),
  "components": require("css.gg/icons/svg/components.svg"),
  "compress-left": require("css.gg/icons/svg/compress-left.svg"),
  "compress-right": require("css.gg/icons/svg/compress-right.svg"),
  "compress-v": require("css.gg/icons/svg/compress-v.svg"),
  "compress": require("css.gg/icons/svg/compress.svg"),
  "controller": require("css.gg/icons/svg/controller.svg"),
  "copy": require("css.gg/icons/svg/copy.svg"),
  "copyright": require("css.gg/icons/svg/copyright.svg"),
  "corner-double-down-left": require("css.gg/icons/svg/corner-double-down-left.svg"),
  "corner-double-down-right": require("css.gg/icons/svg/corner-double-down-right.svg"),
  "corner-double-left-down": require("css.gg/icons/svg/corner-double-left-down.svg"),
  "corner-double-left-up": require("css.gg/icons/svg/corner-double-left-up.svg"),
  "corner-double-right-down": require("css.gg/icons/svg/corner-double-right-down.svg"),
  "corner-double-right-up": require("css.gg/icons/svg/corner-double-right-up.svg"),
  "corner-double-up-left": require("css.gg/icons/svg/corner-double-up-left.svg"),
  "corner-double-up-right": require("css.gg/icons/svg/corner-double-up-right.svg"),
  "corner-down-left": require("css.gg/icons/svg/corner-down-left.svg"),
  "corner-down-right": require("css.gg/icons/svg/corner-down-right.svg"),
  "corner-left-down": require("css.gg/icons/svg/corner-left-down.svg"),
  "corner-left-up": require("css.gg/icons/svg/corner-left-up.svg"),
  "corner-right-down": require("css.gg/icons/svg/corner-right-down.svg"),
  "corner-right-up": require("css.gg/icons/svg/corner-right-up.svg"),
  "corner-up-left": require("css.gg/icons/svg/corner-up-left.svg"),
  "corner-up-right": require("css.gg/icons/svg/corner-up-right.svg"),
  "credit-card": require("css.gg/icons/svg/credit-card.svg"),
  "crop": require("css.gg/icons/svg/crop.svg"),
  "cross": require("css.gg/icons/svg/cross.svg"),
  "crowdfire": require("css.gg/icons/svg/crowdfire.svg"),
  "crown": require("css.gg/icons/svg/crown.svg"),
  "danger": require("css.gg/icons/svg/danger.svg"),
  "dark-mode": require("css.gg/icons/svg/dark-mode.svg"),
  "data": require("css.gg/icons/svg/data.svg"),
  "database": require("css.gg/icons/svg/database.svg"),
  "debug": require("css.gg/icons/svg/debug.svg"),
  "designmodo": require("css.gg/icons/svg/designmodo.svg"),
  "desktop": require("css.gg/icons/svg/desktop.svg"),
  "details-less": require("css.gg/icons/svg/details-less.svg"),
  "details-more": require("css.gg/icons/svg/details-more.svg"),
  "dialpad": require("css.gg/icons/svg/dialpad.svg"),
  "dice-1": require("css.gg/icons/svg/dice-1.svg"),
  "dice-2": require("css.gg/icons/svg/dice-2.svg"),
  "dice-3": require("css.gg/icons/svg/dice-3.svg"),
  "dice-4": require("css.gg/icons/svg/dice-4.svg"),
  "dice-5": require("css.gg/icons/svg/dice-5.svg"),
  "dice-6": require("css.gg/icons/svg/dice-6.svg"),
  "digitalocean": require("css.gg/icons/svg/digitalocean.svg"),
  "disc": require("css.gg/icons/svg/disc.svg"),
  "display-flex": require("css.gg/icons/svg/display-flex.svg"),
  "display-fullwidth": require("css.gg/icons/svg/display-fullwidth.svg"),
  "display-grid": require("css.gg/icons/svg/display-grid.svg"),
  "display-spacing": require("css.gg/icons/svg/display-spacing.svg"),
  "distribute-horizontal": require("css.gg/icons/svg/distribute-horizontal.svg"),
  "distribute-vertical": require("css.gg/icons/svg/distribute-vertical.svg"),
  "dock-bottom": require("css.gg/icons/svg/dock-bottom.svg"),
  "dock-left": require("css.gg/icons/svg/dock-left.svg"),
  "dock-right": require("css.gg/icons/svg/dock-right.svg"),
  "dock-window": require("css.gg/icons/svg/dock-window.svg"),
  "dolby": require("css.gg/icons/svg/dolby.svg"),
  "dollar": require("css.gg/icons/svg/dollar.svg"),
  "dribbble": require("css.gg/icons/svg/dribbble.svg"),
  "drive": require("css.gg/icons/svg/drive.svg"),
  "drop-invert": require("css.gg/icons/svg/drop-invert.svg"),
  "drop-opacity": require("css.gg/icons/svg/drop-opacity.svg"),
  "drop": require("css.gg/icons/svg/drop.svg"),
  "duplicate": require("css.gg/icons/svg/duplicate.svg"),
  "edit-black-point": require("css.gg/icons/svg/edit-black-point.svg"),
  "edit-contrast": require("css.gg/icons/svg/edit-contrast.svg"),
  "edit-exposure": require("css.gg/icons/svg/edit-exposure.svg"),
  "edit-fade": require("css.gg/icons/svg/edit-fade.svg"),
  "edit-flip-h": require("css.gg/icons/svg/edit-flip-h.svg"),
  "edit-flip-v": require("css.gg/icons/svg/edit-flip-v.svg"),
  "edit-highlight": require("css.gg/icons/svg/edit-highlight.svg"),
  "edit-markup": require("css.gg/icons/svg/edit-markup.svg"),
  "edit-mask": require("css.gg/icons/svg/edit-mask.svg"),
  "edit-noise": require("css.gg/icons/svg/edit-noise.svg"),
  "edit-shadows": require("css.gg/icons/svg/edit-shadows.svg"),
  "edit-straight": require("css.gg/icons/svg/edit-straight.svg"),
  "edit-unmask": require("css.gg/icons/svg/edit-unmask.svg"),
  "eject": require("css.gg/icons/svg/eject.svg"),
  "enter": require("css.gg/icons/svg/enter.svg"),
  "erase": require("css.gg/icons/svg/erase.svg"),
  "ereader": require("css.gg/icons/svg/ereader.svg"),
  "ericsson": require("css.gg/icons/svg/ericsson.svg"),
  "ethernet": require("css.gg/icons/svg/ethernet.svg"),
  "euro": require("css.gg/icons/svg/euro.svg"),
  "eventbrite": require("css.gg/icons/svg/eventbrite.svg"),
  "expand": require("css.gg/icons/svg/expand.svg"),
  "export": require("css.gg/icons/svg/export.svg"),
  "extension-add": require("css.gg/icons/svg/extension-add.svg"),
  "extension-alt": require("css.gg/icons/svg/extension-alt.svg"),
  "extension-remove": require("css.gg/icons/svg/extension-remove.svg"),
  "extension": require("css.gg/icons/svg/extension.svg"),
  "external": require("css.gg/icons/svg/external.svg"),
  "eye-alt": require("css.gg/icons/svg/eye-alt.svg"),
  "eye": require("css.gg/icons/svg/eye.svg"),
  "facebook": require("css.gg/icons/svg/facebook.svg"),
  "feed": require("css.gg/icons/svg/feed.svg"),
  "figma": require("css.gg/icons/svg/figma.svg"),
  "file-add": require("css.gg/icons/svg/file-add.svg"),
  "file-document": require("css.gg/icons/svg/file-document.svg"),
  "file-remove": require("css.gg/icons/svg/file-remove.svg"),
  "file": require("css.gg/icons/svg/file.svg"),
  "film": require("css.gg/icons/svg/film.svg"),
  "filters": require("css.gg/icons/svg/filters.svg"),
  "flag-alt": require("css.gg/icons/svg/flag-alt.svg"),
  "flag": require("css.gg/icons/svg/flag.svg"),
  "folder-add": require("css.gg/icons/svg/folder-add.svg"),
  "folder-remove": require("css.gg/icons/svg/folder-remove.svg"),
  "folder": require("css.gg/icons/svg/folder.svg"),
  "font-height": require("css.gg/icons/svg/font-height.svg"),
  "font-spacing": require("css.gg/icons/svg/font-spacing.svg"),
  "format-bold": require("css.gg/icons/svg/format-bold.svg"),
  "format-center": require("css.gg/icons/svg/format-center.svg"),
  "format-color": require("css.gg/icons/svg/format-color.svg"),
  "format-heading": require("css.gg/icons/svg/format-heading.svg"),
  "format-indent-decrease": require("css.gg/icons/svg/format-indent-decrease.svg"),
  "format-indent-increase": require("css.gg/icons/svg/format-indent-increase.svg"),
  "format-italic": require("css.gg/icons/svg/format-italic.svg"),
  "format-justify": require("css.gg/icons/svg/format-justify.svg"),
  "format-left": require("css.gg/icons/svg/format-left.svg"),
  "format-line-height": require("css.gg/icons/svg/format-line-height.svg"),
  "format-right": require("css.gg/icons/svg/format-right.svg"),
  "format-separator": require("css.gg/icons/svg/format-separator.svg"),
  "format-slash": require("css.gg/icons/svg/format-slash.svg"),
  "format-strike": require("css.gg/icons/svg/format-strike.svg"),
  "format-text": require("css.gg/icons/svg/format-text.svg"),
  "format-underline": require("css.gg/icons/svg/format-underline.svg"),
  "format-uppercase": require("css.gg/icons/svg/format-uppercase.svg"),
  "framer": require("css.gg/icons/svg/framer.svg"),
  "games": require("css.gg/icons/svg/games.svg"),
  "gender-female": require("css.gg/icons/svg/gender-female.svg"),
  "gender-male": require("css.gg/icons/svg/gender-male.svg"),
  "ghost-character": require("css.gg/icons/svg/ghost-character.svg"),
  "ghost": require("css.gg/icons/svg/ghost.svg"),
  "gift": require("css.gg/icons/svg/gift.svg"),
  "girl": require("css.gg/icons/svg/girl.svg"),
  "git-branch": require("css.gg/icons/svg/git-branch.svg"),
  "git-commit": require("css.gg/icons/svg/git-commit.svg"),
  "git-fork": require("css.gg/icons/svg/git-fork.svg"),
  "git-pull": require("css.gg/icons/svg/git-pull.svg"),
  "gitter": require("css.gg/icons/svg/gitter.svg"),
  "glass-alt": require("css.gg/icons/svg/glass-alt.svg"),
  "glass": require("css.gg/icons/svg/glass.svg"),
  "globe-alt": require("css.gg/icons/svg/globe-alt.svg"),
  "globe": require("css.gg/icons/svg/globe.svg"),
  "google-tasks": require("css.gg/icons/svg/google-tasks.svg"),
  "google": require("css.gg/icons/svg/google.svg"),
  "gym": require("css.gg/icons/svg/gym.svg"),
  "hashtag": require("css.gg/icons/svg/hashtag.svg"),
  "headset": require("css.gg/icons/svg/headset.svg"),
  "heart": require("css.gg/icons/svg/heart.svg"),
  "hello": require("css.gg/icons/svg/hello.svg"),
  "home-alt": require("css.gg/icons/svg/home-alt.svg"),
  "home-screen": require("css.gg/icons/svg/home-screen.svg"),
  "home": require("css.gg/icons/svg/home.svg"),
  "icecream": require("css.gg/icons/svg/icecream.svg"),
  "if-design": require("css.gg/icons/svg/if-design.svg"),
  "image": require("css.gg/icons/svg/image.svg"),
  "import": require("css.gg/icons/svg/import.svg"),
  "inbox": require("css.gg/icons/svg/inbox.svg"),
  "indie-hackers": require("css.gg/icons/svg/indie-hackers.svg"),
  "infinity": require("css.gg/icons/svg/infinity.svg"),
  "info": require("css.gg/icons/svg/info.svg"),
  "inpicture": require("css.gg/icons/svg/inpicture.svg"),
  "insert-after-o": require("css.gg/icons/svg/insert-after-o.svg"),
  "insert-after-r": require("css.gg/icons/svg/insert-after-r.svg"),
  "insert-after": require("css.gg/icons/svg/insert-after.svg"),
  "insert-before-o": require("css.gg/icons/svg/insert-before-o.svg"),
  "insert-before-r": require("css.gg/icons/svg/insert-before-r.svg"),
  "insert-before": require("css.gg/icons/svg/insert-before.svg"),
  "insights": require("css.gg/icons/svg/insights.svg"),
  "instagram": require("css.gg/icons/svg/instagram.svg"),
  "internal": require("css.gg/icons/svg/internal.svg"),
  "key": require("css.gg/icons/svg/key.svg"),
  "keyboard": require("css.gg/icons/svg/keyboard.svg"),
  "keyhole": require("css.gg/icons/svg/keyhole.svg"),
  "laptop": require("css.gg/icons/svg/laptop.svg"),
  "lastpass": require("css.gg/icons/svg/lastpass.svg"),
  "layout-grid-small": require("css.gg/icons/svg/layout-grid-small.svg"),
  "layout-grid": require("css.gg/icons/svg/layout-grid.svg"),
  "layout-list": require("css.gg/icons/svg/layout-list.svg"),
  "layout-pin": require("css.gg/icons/svg/layout-pin.svg"),
  "linear": require("css.gg/icons/svg/linear.svg"),
  "link": require("css.gg/icons/svg/link.svg"),
  "list-tree": require("css.gg/icons/svg/list-tree.svg"),
  "list": require("css.gg/icons/svg/list.svg"),
  "live-photo": require("css.gg/icons/svg/live-photo.svg"),
  "loadbar-alt": require("css.gg/icons/svg/loadbar-alt.svg"),
  "loadbar-doc": require("css.gg/icons/svg/loadbar-doc.svg"),
  "loadbar-sound": require("css.gg/icons/svg/loadbar-sound.svg"),
  "loadbar": require("css.gg/icons/svg/loadbar.svg"),
  "lock-unlock": require("css.gg/icons/svg/lock-unlock.svg"),
  "lock": require("css.gg/icons/svg/lock.svg"),
  "log-in": require("css.gg/icons/svg/log-in.svg"),
  "log-off": require("css.gg/icons/svg/log-off.svg"),
  "log-out": require("css.gg/icons/svg/log-out.svg"),
  "loupe": require("css.gg/icons/svg/loupe.svg"),
  "magnet": require("css.gg/icons/svg/magnet.svg"),
  "mail-forward": require("css.gg/icons/svg/mail-forward.svg"),
  "mail-open": require("css.gg/icons/svg/mail-open.svg"),
  "mail-reply": require("css.gg/icons/svg/mail-reply.svg"),
  "mail": require("css.gg/icons/svg/mail.svg"),
  "math-divide": require("css.gg/icons/svg/math-divide.svg"),
  "math-equal": require("css.gg/icons/svg/math-equal.svg"),
  "math-minus": require("css.gg/icons/svg/math-minus.svg"),
  "math-percent": require("css.gg/icons/svg/math-percent.svg"),
  "math-plus": require("css.gg/icons/svg/math-plus.svg"),
  "maximize-alt": require("css.gg/icons/svg/maximize-alt.svg"),
  "maximize": require("css.gg/icons/svg/maximize.svg"),
  "maze": require("css.gg/icons/svg/maze.svg"),
  "media-live": require("css.gg/icons/svg/media-live.svg"),
  "media-podcast": require("css.gg/icons/svg/media-podcast.svg"),
  "menu-boxed": require("css.gg/icons/svg/menu-boxed.svg"),
  "menu-cake": require("css.gg/icons/svg/menu-cake.svg"),
  "menu-cheese": require("css.gg/icons/svg/menu-cheese.svg"),
  "menu-grid-o": require("css.gg/icons/svg/menu-grid-o.svg"),
  "menu-grid-r": require("css.gg/icons/svg/menu-grid-r.svg"),
  "menu-hotdog": require("css.gg/icons/svg/menu-hotdog.svg"),
  "menu-left-alt": require("css.gg/icons/svg/menu-left-alt.svg"),
  "menu-left": require("css.gg/icons/svg/menu-left.svg"),
  "menu-motion": require("css.gg/icons/svg/menu-motion.svg"),
  "menu-oreos": require("css.gg/icons/svg/menu-oreos.svg"),
  "menu-right-alt": require("css.gg/icons/svg/menu-right-alt.svg"),
  "menu-right": require("css.gg/icons/svg/menu-right.svg"),
  "menu-round": require("css.gg/icons/svg/menu-round.svg"),
  "menu": require("css.gg/icons/svg/menu.svg"),
  "merge-horizontal": require("css.gg/icons/svg/merge-horizontal.svg"),
  "merge-vertical": require("css.gg/icons/svg/merge-vertical.svg"),
  "mic": require("css.gg/icons/svg/mic.svg"),
  "microbit": require("css.gg/icons/svg/microbit.svg"),
  "microsoft": require("css.gg/icons/svg/microsoft.svg"),
  "mini-player": require("css.gg/icons/svg/mini-player.svg"),
  "minimize-alt": require("css.gg/icons/svg/minimize-alt.svg"),
  "minimize": require("css.gg/icons/svg/minimize.svg"),
  "modem": require("css.gg/icons/svg/modem.svg"),
  "monday": require("css.gg/icons/svg/monday.svg"),
  "moon": require("css.gg/icons/svg/moon.svg"),
  "more-alt": require("css.gg/icons/svg/more-alt.svg"),
  "more-o": require("css.gg/icons/svg/more-o.svg"),
  "more-r": require("css.gg/icons/svg/more-r.svg"),
  "more-vertical-alt": require("css.gg/icons/svg/more-vertical-alt.svg"),
  "more-vertical-o": require("css.gg/icons/svg/more-vertical-o.svg"),
  "more-vertical-r": require("css.gg/icons/svg/more-vertical-r.svg"),
  "more-vertical": require("css.gg/icons/svg/more-vertical.svg"),
  "more": require("css.gg/icons/svg/more.svg"),
  "mouse": require("css.gg/icons/svg/mouse.svg"),
  "move-down": require("css.gg/icons/svg/move-down.svg"),
  "move-left": require("css.gg/icons/svg/move-left.svg"),
  "move-right": require("css.gg/icons/svg/move-right.svg"),
  "move-task": require("css.gg/icons/svg/move-task.svg"),
  "move-up": require("css.gg/icons/svg/move-up.svg"),
  "music-note": require("css.gg/icons/svg/music-note.svg"),
  "music-speaker": require("css.gg/icons/svg/music-speaker.svg"),
  "music": require("css.gg/icons/svg/music.svg"),
  "nametag": require("css.gg/icons/svg/nametag.svg"),
  "notes": require("css.gg/icons/svg/notes.svg"),
  "notifications": require("css.gg/icons/svg/notifications.svg"),
  "npm": require("css.gg/icons/svg/npm.svg"),
  "oculus": require("css.gg/icons/svg/oculus.svg"),
  "open-collective": require("css.gg/icons/svg/open-collective.svg"),
  "options": require("css.gg/icons/svg/options.svg"),
  "organisation": require("css.gg/icons/svg/organisation.svg"),
  "overflow": require("css.gg/icons/svg/overflow.svg"),
  "pacman": require("css.gg/icons/svg/pacman.svg"),
  "password": require("css.gg/icons/svg/password.svg"),
  "path-back": require("css.gg/icons/svg/path-back.svg"),
  "path-crop": require("css.gg/icons/svg/path-crop.svg"),
  "path-divide": require("css.gg/icons/svg/path-divide.svg"),
  "path-exclude": require("css.gg/icons/svg/path-exclude.svg"),
  "path-front": require("css.gg/icons/svg/path-front.svg"),
  "path-intersect": require("css.gg/icons/svg/path-intersect.svg"),
  "path-outline": require("css.gg/icons/svg/path-outline.svg"),
  "path-trim": require("css.gg/icons/svg/path-trim.svg"),
  "path-unite": require("css.gg/icons/svg/path-unite.svg"),
  "patreon": require("css.gg/icons/svg/patreon.svg"),
  "paypal": require("css.gg/icons/svg/paypal.svg"),
  "pen": require("css.gg/icons/svg/pen.svg"),
  "pentagon-bottom-left": require("css.gg/icons/svg/pentagon-bottom-left.svg"),
  "pentagon-bottom-right": require("css.gg/icons/svg/pentagon-bottom-right.svg"),
  "pentagon-down": require("css.gg/icons/svg/pentagon-down.svg"),
  "pentagon-left": require("css.gg/icons/svg/pentagon-left.svg"),
  "pentagon-right": require("css.gg/icons/svg/pentagon-right.svg"),
  "pentagon-top-left": require("css.gg/icons/svg/pentagon-top-left.svg"),
  "pentagon-top-right": require("css.gg/icons/svg/pentagon-top-right.svg"),
  "pentagon-up": require("css.gg/icons/svg/pentagon-up.svg"),
  "performance": require("css.gg/icons/svg/performance.svg"),
  "pexels": require("css.gg/icons/svg/pexels.svg"),
  "phone": require("css.gg/icons/svg/phone.svg"),
  "photoscan": require("css.gg/icons/svg/photoscan.svg"),
  "piano": require("css.gg/icons/svg/piano.svg"),
  "pill": require("css.gg/icons/svg/pill.svg"),
  "pin-alt": require("css.gg/icons/svg/pin-alt.svg"),
  "pin-bottom": require("css.gg/icons/svg/pin-bottom.svg"),
  "pin-top": require("css.gg/icons/svg/pin-top.svg"),
  "pin": require("css.gg/icons/svg/pin.svg"),
  "play-backwards": require("css.gg/icons/svg/play-backwards.svg"),
  "play-button-o": require("css.gg/icons/svg/play-button-o.svg"),
  "play-button-r": require("css.gg/icons/svg/play-button-r.svg"),
  "play-button": require("css.gg/icons/svg/play-button.svg"),
  "play-forwards": require("css.gg/icons/svg/play-forwards.svg"),
  "play-list-add": require("css.gg/icons/svg/play-list-add.svg"),
  "play-list-check": require("css.gg/icons/svg/play-list-check.svg"),
  "play-list-remove": require("css.gg/icons/svg/play-list-remove.svg"),
  "play-list-search": require("css.gg/icons/svg/play-list-search.svg"),
  "play-list": require("css.gg/icons/svg/play-list.svg"),
  "play-pause-o": require("css.gg/icons/svg/play-pause-o.svg"),
  "play-pause-r": require("css.gg/icons/svg/play-pause-r.svg"),
  "play-pause": require("css.gg/icons/svg/play-pause.svg"),
  "play-stop-o": require("css.gg/icons/svg/play-stop-o.svg"),
  "play-stop-r": require("css.gg/icons/svg/play-stop-r.svg"),
  "play-stop": require("css.gg/icons/svg/play-stop.svg"),
  "play-track-next-o": require("css.gg/icons/svg/play-track-next-o.svg"),
  "play-track-next-r": require("css.gg/icons/svg/play-track-next-r.svg"),
  "play-track-next": require("css.gg/icons/svg/play-track-next.svg"),
  "play-track-prev-o": require("css.gg/icons/svg/play-track-prev-o.svg"),
  "play-track-prev-r": require("css.gg/icons/svg/play-track-prev-r.svg"),
  "play-track-prev": require("css.gg/icons/svg/play-track-prev.svg"),
  "plug": require("css.gg/icons/svg/plug.svg"),
  "pocket": require("css.gg/icons/svg/pocket.svg"),
  "pokemon": require("css.gg/icons/svg/pokemon.svg"),
  "polaroid": require("css.gg/icons/svg/polaroid.svg"),
  "poll": require("css.gg/icons/svg/poll.svg"),
  "presentation": require("css.gg/icons/svg/presentation.svg"),
  "printer": require("css.gg/icons/svg/printer.svg"),
  "product-hunt": require("css.gg/icons/svg/product-hunt.svg"),
  "profile": require("css.gg/icons/svg/profile.svg"),
  "pull-clear": require("css.gg/icons/svg/pull-clear.svg"),
  "push-chevron-down-o": require("css.gg/icons/svg/push-chevron-down-o.svg"),
  "push-chevron-down-r": require("css.gg/icons/svg/push-chevron-down-r.svg"),
  "push-chevron-down": require("css.gg/icons/svg/push-chevron-down.svg"),
  "push-chevron-left-o": require("css.gg/icons/svg/push-chevron-left-o.svg"),
  "push-chevron-left-r": require("css.gg/icons/svg/push-chevron-left-r.svg"),
  "push-chevron-left": require("css.gg/icons/svg/push-chevron-left.svg"),
  "push-chevron-right-o": require("css.gg/icons/svg/push-chevron-right-o.svg"),
  "push-chevron-right-r": require("css.gg/icons/svg/push-chevron-right-r.svg"),
  "push-chevron-right": require("css.gg/icons/svg/push-chevron-right.svg"),
  "push-chevron-up-o": require("css.gg/icons/svg/push-chevron-up-o.svg"),
  "push-chevron-up-r": require("css.gg/icons/svg/push-chevron-up-r.svg"),
  "push-chevron-up": require("css.gg/icons/svg/push-chevron-up.svg"),
  "push-down": require("css.gg/icons/svg/push-down.svg"),
  "push-left": require("css.gg/icons/svg/push-left.svg"),
  "push-right": require("css.gg/icons/svg/push-right.svg"),
  "push-up": require("css.gg/icons/svg/push-up.svg"),
  "qr": require("css.gg/icons/svg/qr.svg"),
  "quote-o": require("css.gg/icons/svg/quote-o.svg"),
  "quote": require("css.gg/icons/svg/quote.svg"),
  "radio-check": require("css.gg/icons/svg/radio-check.svg"),
  "radio-checked": require("css.gg/icons/svg/radio-checked.svg"),
  "ratio": require("css.gg/icons/svg/ratio.svg"),
  "read": require("css.gg/icons/svg/read.svg"),
  "readme": require("css.gg/icons/svg/readme.svg"),
  "record": require("css.gg/icons/svg/record.svg"),
  "redo": require("css.gg/icons/svg/redo.svg"),
  "remote": require("css.gg/icons/svg/remote.svg"),
  "remove-r": require("css.gg/icons/svg/remove-r.svg"),
  "remove": require("css.gg/icons/svg/remove.svg"),
  "rename": require("css.gg/icons/svg/rename.svg"),
  "reorder": require("css.gg/icons/svg/reorder.svg"),
  "repeat": require("css.gg/icons/svg/repeat.svg"),
  "ring": require("css.gg/icons/svg/ring.svg"),
  "row-first": require("css.gg/icons/svg/row-first.svg"),
  "row-last": require("css.gg/icons/svg/row-last.svg"),
  "ruler": require("css.gg/icons/svg/ruler.svg"),
  "sand-clock": require("css.gg/icons/svg/sand-clock.svg"),
  "scan": require("css.gg/icons/svg/scan.svg"),
  "screen-mirror": require("css.gg/icons/svg/screen-mirror.svg"),
  "screen-shot": require("css.gg/icons/svg/screen-shot.svg"),
  "screen-wide": require("css.gg/icons/svg/screen-wide.svg"),
  "screen": require("css.gg/icons/svg/screen.svg"),
  "scroll-h": require("css.gg/icons/svg/scroll-h.svg"),
  "scroll-v": require("css.gg/icons/svg/scroll-v.svg"),
  "search-found": require("css.gg/icons/svg/search-found.svg"),
  "search-loading": require("css.gg/icons/svg/search-loading.svg"),
  "search": require("css.gg/icons/svg/search.svg"),
  "select-o": require("css.gg/icons/svg/select-o.svg"),
  "select-r": require("css.gg/icons/svg/select-r.svg"),
  "select": require("css.gg/icons/svg/select.svg"),
  "server": require("css.gg/icons/svg/server.svg"),
  "serverless": require("css.gg/icons/svg/serverless.svg"),
  "shape-circle": require("css.gg/icons/svg/shape-circle.svg"),
  "shape-half-circle": require("css.gg/icons/svg/shape-half-circle.svg"),
  "shape-hexagon": require("css.gg/icons/svg/shape-hexagon.svg"),
  "shape-rhombus": require("css.gg/icons/svg/shape-rhombus.svg"),
  "shape-square": require("css.gg/icons/svg/shape-square.svg"),
  "shape-triangle": require("css.gg/icons/svg/shape-triangle.svg"),
  "shape-zigzag": require("css.gg/icons/svg/shape-zigzag.svg"),
  "share": require("css.gg/icons/svg/share.svg"),
  "shield": require("css.gg/icons/svg/shield.svg"),
  "shopping-bag": require("css.gg/icons/svg/shopping-bag.svg"),
  "shopping-cart": require("css.gg/icons/svg/shopping-cart.svg"),
  "shortcut": require("css.gg/icons/svg/shortcut.svg"),
  "shutterstock": require("css.gg/icons/svg/shutterstock.svg"),
  "sidebar-open": require("css.gg/icons/svg/sidebar-open.svg"),
  "sidebar-right": require("css.gg/icons/svg/sidebar-right.svg"),
  "sidebar": require("css.gg/icons/svg/sidebar.svg"),
  "signal": require("css.gg/icons/svg/signal.svg"),
  "size": require("css.gg/icons/svg/size.svg"),
  "sketch": require("css.gg/icons/svg/sketch.svg"),
  "slack": require("css.gg/icons/svg/slack.svg"),
  "sleep": require("css.gg/icons/svg/sleep.svg"),
  "smart-home-boiler": require("css.gg/icons/svg/smart-home-boiler.svg"),
  "smart-home-cooker": require("css.gg/icons/svg/smart-home-cooker.svg"),
  "smart-home-heat": require("css.gg/icons/svg/smart-home-heat.svg"),
  "smart-home-light": require("css.gg/icons/svg/smart-home-light.svg"),
  "smart-home-refrigerator": require("css.gg/icons/svg/smart-home-refrigerator.svg"),
  "smart-home-wash-machine": require("css.gg/icons/svg/smart-home-wash-machine.svg"),
  "smartphone-chip": require("css.gg/icons/svg/smartphone-chip.svg"),
  "smartphone-ram": require("css.gg/icons/svg/smartphone-ram.svg"),
  "smartphone-shake": require("css.gg/icons/svg/smartphone-shake.svg"),
  "smartphone": require("css.gg/icons/svg/smartphone.svg"),
  "smile-mouth-open": require("css.gg/icons/svg/smile-mouth-open.svg"),
  "smile-neutral": require("css.gg/icons/svg/smile-neutral.svg"),
  "smile-no-mouth": require("css.gg/icons/svg/smile-no-mouth.svg"),
  "smile-none": require("css.gg/icons/svg/smile-none.svg"),
  "smile-sad": require("css.gg/icons/svg/smile-sad.svg"),
  "smile-upside": require("css.gg/icons/svg/smile-upside.svg"),
  "smile": require("css.gg/icons/svg/smile.svg"),
  "software-download": require("css.gg/icons/svg/software-download.svg"),
  "software-upload": require("css.gg/icons/svg/software-upload.svg"),
  "sort-az": require("css.gg/icons/svg/sort-az.svg"),
  "sort-za": require("css.gg/icons/svg/sort-za.svg"),
  "space-between-v": require("css.gg/icons/svg/space-between-v.svg"),
  "space-between": require("css.gg/icons/svg/space-between.svg"),
  "spectrum": require("css.gg/icons/svg/spectrum.svg"),
  "spinner-alt": require("css.gg/icons/svg/spinner-alt.svg"),
  "spinner-two-alt": require("css.gg/icons/svg/spinner-two-alt.svg"),
  "spinner-two": require("css.gg/icons/svg/spinner-two.svg"),
  "spinner": require("css.gg/icons/svg/spinner.svg"),
  "square": require("css.gg/icons/svg/square.svg"),
  "stack": require("css.gg/icons/svg/stack.svg"),
  "stark": require("css.gg/icons/svg/stark.svg"),
  "stopwatch": require("css.gg/icons/svg/stopwatch.svg"),
  "stories": require("css.gg/icons/svg/stories.svg"),
  "studio": require("css.gg/icons/svg/studio.svg"),
  "style": require("css.gg/icons/svg/style.svg"),
  "sun": require("css.gg/icons/svg/sun.svg"),
  "support": require("css.gg/icons/svg/support.svg"),
  "swap-vertical": require("css.gg/icons/svg/swap-vertical.svg"),
  "swap": require("css.gg/icons/svg/swap.svg"),
  "sweden": require("css.gg/icons/svg/sweden.svg"),
  "swiss": require("css.gg/icons/svg/swiss.svg"),
  "sync": require("css.gg/icons/svg/sync.svg"),
  "tab": require("css.gg/icons/svg/tab.svg"),
  "tag": require("css.gg/icons/svg/tag.svg"),
  "tally": require("css.gg/icons/svg/tally.svg"),
  "tap-double": require("css.gg/icons/svg/tap-double.svg"),
  "tap-single": require("css.gg/icons/svg/tap-single.svg"),
  "template": require("css.gg/icons/svg/template.svg"),
  "tennis": require("css.gg/icons/svg/tennis.svg"),
  "terminal": require("css.gg/icons/svg/terminal.svg"),
  "terrain": require("css.gg/icons/svg/terrain.svg"),
  "thermometer": require("css.gg/icons/svg/thermometer.svg"),
  "thermostat": require("css.gg/icons/svg/thermostat.svg"),
  "tikcode": require("css.gg/icons/svg/tikcode.svg"),
  "time": require("css.gg/icons/svg/time.svg"),
  "timelapse": require("css.gg/icons/svg/timelapse.svg"),
  "timer": require("css.gg/icons/svg/timer.svg"),
  "today": require("css.gg/icons/svg/today.svg"),
  "toggle-off": require("css.gg/icons/svg/toggle-off.svg"),
  "toggle-on": require("css.gg/icons/svg/toggle-on.svg"),
  "toggle-square-off": require("css.gg/icons/svg/toggle-square-off.svg"),
  "toggle-square": require("css.gg/icons/svg/toggle-square.svg"),
  "toolbar-bottom": require("css.gg/icons/svg/toolbar-bottom.svg"),
  "toolbar-left": require("css.gg/icons/svg/toolbar-left.svg"),
  "toolbar-right": require("css.gg/icons/svg/toolbar-right.svg"),
  "toolbar-top": require("css.gg/icons/svg/toolbar-top.svg"),
  "toolbox": require("css.gg/icons/svg/toolbox.svg"),
  "touchpad": require("css.gg/icons/svg/touchpad.svg"),
  "track": require("css.gg/icons/svg/track.svg"),
  "transcript": require("css.gg/icons/svg/transcript.svg"),
  "trash-empty": require("css.gg/icons/svg/trash-empty.svg"),
  "trash": require("css.gg/icons/svg/trash.svg"),
  "tree": require("css.gg/icons/svg/tree.svg"),
  "trees": require("css.gg/icons/svg/trees.svg"),
  "trello": require("css.gg/icons/svg/trello.svg"),
  "trending-down": require("css.gg/icons/svg/trending-down.svg"),
  "trending": require("css.gg/icons/svg/trending.svg"),
  "trophy": require("css.gg/icons/svg/trophy.svg"),
  "tv": require("css.gg/icons/svg/tv.svg"),
  "twilio": require("css.gg/icons/svg/twilio.svg"),
  "twitter": require("css.gg/icons/svg/twitter.svg"),
  "ui-kit": require("css.gg/icons/svg/ui-kit.svg"),
  "umbrella": require("css.gg/icons/svg/umbrella.svg"),
  "unavailable": require("css.gg/icons/svg/unavailable.svg"),
  "unblock": require("css.gg/icons/svg/unblock.svg"),
  "undo": require("css.gg/icons/svg/undo.svg"),
  "unfold": require("css.gg/icons/svg/unfold.svg"),
  "unsplash": require("css.gg/icons/svg/unsplash.svg"),
  "usb-c": require("css.gg/icons/svg/usb-c.svg"),
  "usb": require("css.gg/icons/svg/usb.svg"),
  "user-add": require("css.gg/icons/svg/user-add.svg"),
  "user-list": require("css.gg/icons/svg/user-list.svg"),
  "user-remove": require("css.gg/icons/svg/user-remove.svg"),
  "user": require("css.gg/icons/svg/user.svg"),
  "userlane": require("css.gg/icons/svg/userlane.svg"),
  "view-cols": require("css.gg/icons/svg/view-cols.svg"),
  "view-comfortable": require("css.gg/icons/svg/view-comfortable.svg"),
  "view-day": require("css.gg/icons/svg/view-day.svg"),
  "view-grid": require("css.gg/icons/svg/view-grid.svg"),
  "view-list": require("css.gg/icons/svg/view-list.svg"),
  "view-month": require("css.gg/icons/svg/view-month.svg"),
  "view-split": require("css.gg/icons/svg/view-split.svg"),
  "vinyl": require("css.gg/icons/svg/vinyl.svg"),
  "voicemail-o": require("css.gg/icons/svg/voicemail-o.svg"),
  "voicemail-r": require("css.gg/icons/svg/voicemail-r.svg"),
  "voicemail": require("css.gg/icons/svg/voicemail.svg"),
  "volume": require("css.gg/icons/svg/volume.svg"),
  "webcam": require("css.gg/icons/svg/webcam.svg"),
  "website": require("css.gg/icons/svg/website.svg"),
  "windows": require("css.gg/icons/svg/windows.svg"),
  "work-alt": require("css.gg/icons/svg/work-alt.svg"),
  "yinyang": require("css.gg/icons/svg/yinyang.svg"),
  "youtube": require("css.gg/icons/svg/youtube.svg"),
  "zeit": require("css.gg/icons/svg/zeit.svg"),
  "zoom-in": require("css.gg/icons/svg/zoom-in.svg"),
  "zoom-out": require("css.gg/icons/svg/zoom-out.svg"),
  // HERO ICONS
  "refresh": require("../assets/icons/heroicons/refresh.svg"),
  "bell2": require("../assets/icons/heroicons/bell.svg"),
  "users": require("../assets/icons/heroicons/users.svg"),
  "running": require("../assets/icons/heroicons/running.svg"),
  "hammer": require("../assets/icons/heroicons/hammer.svg"),
  "handshake": require("../assets/icons/heroicons/handshake.svg"),
  "exchange": require("../assets/icons/heroicons/exchange.svg"),
  "history": require("../assets/icons/heroicons/history.svg"),
  "hourglass_change": require("../assets/icons/heroicons/hourglass_change.svg"),
  "hat": require("../assets/icons/heroicons/hat.svg"),
  "cog": require("../assets/icons/heroicons/cog.svg")
}